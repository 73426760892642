import React from 'react';
import './Hidrophonick.css'
import hidroponik from '../assets/hidroponik.png'
import ServicesCardFirst from '../components/ServicesCardFirst';

function Hidrophonick() {
  const popupContent = {
    title: "Hızlı İletişim",
    description: "Daha fazla bilgi ve özel teklifler için bizimle iletişime geçin.",
    redirectTo: "/contact"
  };

  return(
    <div className="App service-background">
      <div className='container'>
      <ServicesCardFirst
            image={hidroponik}
            title="Blancarc ve Hidroponik Tarım"
            content="Blancarc olarak, sürdürülebilir peyzaj mimarlığını desteklemek amacıyla hidroponik tarım sistemlerini projelerimize entegre ediyoruz. Topraksız tarım yöntemi sayesinde, suyu daha verimli kullanarak, doğal kaynakları koruyarak ve minimum alanla maksimum verim elde ederek çevre dostu üretim sağlıyoruz. Hidroponik tarım, modern yeşil alan tasarımlarımızın bir parçası olarak doğayla uyumlu, yenilikçi ve sürdürülebilir bir geleceğe katkı sunuyor."
            popupContent={popupContent}
            popupImage={hidroponik}
        />
      </div>
    </div>
  );
};

export default Hidrophonick;